<template>
  <div class="product-selector" :class="{'vertical': vertical}" v-if="!loading">
      <product-avatar v-for="(product, idx) of productList" v-bind:key="idx"
        :product="product" :selected="isSelected(product)" clickable :vertical="vertical"
                      @click="selectProduct(product)"></product-avatar>
  </div>
</template>
<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import ProductAvatar from '@/components/ProductAvatar.vue'

export default {
  name: 'product-selector',
  components: {ProductAvatar},
  props: {
    products: Array,
    product: [Object, Array],
    productId: [String, Array],
    product_types: Array,
    display: String,
    select_mode: String,
    vertical: Boolean
  },
  data: function () {
    return {
      loading: true,
      productsInternal: [{
        name: '',
        selected: false
      }],
      selected: null
    }
  },
  async created () {
    if (this.products) {
      this.updateProducts(this.products)
    } else {
      await this.getProducts()
    }
    let selected = null
    console.log('Product', this.product)
    if (this.product && Array.isArray(this.product)) {
      let productIds = this.product.map(p => p.id)
      selected = this.productsInternal.filter(x => productIds.includes(x.id))
    } else if (this.product && !Array.isArray(this.product)) {
      selected = this.productsInternal.find(x => x.id === this.product.id)
    } else if (this.productId && Array.isArray(this.productId)) {
      selected = this.productsInternal.find(x => this.productId.includes(x.product_id))
    } else if (this.productId && !Array.isArray(this.productId)) {
      selected = this.productsInternal.find(x => x.product_id === this.productId)
    }
    if (this.select_mode === 'multi' && !Array.isArray(selected)) {
      this.selected = selected ? [selected] : []
    } else {
      this.selected = selected
    }
    console.log('Selected: ', this.selected)
    this.loading = false
  },
  methods: {
    getProducts: async function () {
      let resp = await DataProvider.getProducts()
      if (resp.success) {
        this.updateProducts(resp.data)
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    // Update products displayed with the provided array
    updateProducts: function (products) {
      this.productsInternal = [...products]
    },
    // Selection Event
    selectProduct: function (product) {
      if (this.select_mode === 'multi') {
        if (this.selected.includes(product)) {
          this.selected = this.selected.filter(p => p !== product)
        } else {
          this.selected.push(product)
        }
      } else if (this.selected === product) {
        this.selected = null
      } else {
        this.selected = product
      }

      this.$emit('select', this.selected)
    },
    isSelected(product) {
      if (this.select_mode === 'multi') {
        return this.selected.includes(product)
      } else {
        return this.selected === product
      }
    },
    getSortVal(p) {
      if (Object.hasOwn(p.data, 'index_hint')) {
        console.log(p.data.index_hint)
        return p.data.index_hint
      } else {
        return 9999
      }
    }
  },
  computed: {
    productList () {
      if (this.productsInternal) {
        let products = this.productsInternal.filter(p => (!this.product_types || this.product_types.includes(p.product_type)) && p.published)
        products = products.sort((a, b) => this.getSortVal(a) - this.getSortVal(b))
        return products
      } else {
        return []
      }
    }
  },
  watch: {
    products: async function (newVal) {
      this.updateProducts(newVal)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../variables';

  .product-selector {
    display: flex;
    flex-direction: column;
    justify-items: center;
    //width: 100%;
  }

  .product-selector.vertical {
    display: flex;
    flex-direction: row;
    //justify-content: center;
  }

  .product-row {
    display: flex;
    flex-direction: column;
    background: $theme-color-background-2;
    border: 1px solid $theme-color-primary-5;
    border-radius: 10px;
    margin: 10px 5px;
    justify-content: space-between;
  }

  .product-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 10px;
    flex-grow: 1;
    padding: 5px;
  }


  //
  //.product-check {
  //  font-size: 2em;
  //  color: $theme-color-primary-2;
  //  width: 2em;
  //}
  //
  //.product-content {
  //  display: flex;
  //  flex-direction: column;
  //  align-content: center;
  //  justify-content: center;
  //  font-size: 0.75em;
  //  flex-grow: 4;
  //}
  //
  //.product-content h3 {
  //  margin-bottom: 0;
  //}
  //
  //.product-price{
  //  font-size: 2em;
  //  color: $theme-color-primary-2;
  //  flex-grow: 1;
  //}
  //
  //.product-row:hover {
  //  background: $theme-color-background-1;
  //  border-color: $theme-color-primary-3;
  //  h3 {
  //    color: white;
  //  }
  //}
  //
  //.selected {
  //  background: $theme-color-background-1;
  //  border: 2px solid $theme-color-primary-3;
  //}

  .product-html-description {
    //border: 1px solid green;
    //border-radius: 10px 10px 0 0;
    //border-top: none;
  }

  @media screen and (max-width: 600px) {
    .product-price {
      font-size: 1.2em;
    }

    h3 {
      font-size: 1.5em;
    }

  }
</style>
