var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscription-summary" }, [
    _c(
      "div",
      { staticClass: "justify-content-start flex-grow-half" },
      [
        _c(
          "div",
          {
            staticClass: "flex-row justify-content-between align-items-center"
          },
          [
            _c("h5", { staticClass: "text-left" }, [
              _vm._v("Primary Subscription Tier")
            ]),
            _vm.show_change_buttons
              ? _c("button", { on: { click: _vm.clickPrimary } }, [
                  _vm._v("Change")
                ])
              : _vm._e()
          ]
        ),
        _vm.primaryProduct
          ? _c("product-avatar", {
              attrs: { product: _vm.primaryProduct, show_desc_on_click: "" },
              on: {
                click: function($event) {
                  _vm.showPrimaryDescription = !_vm.showPrimaryDescription
                }
              }
            })
          : _vm._e(),
        _c("div", { staticClass: "flex-row justify-content-between" }, [
          _c("h5", { staticClass: "text-left" }, [_vm._v("Service Addons")]),
          _vm.show_change_buttons
            ? _c("button", { on: { click: _vm.clickSecondary } }, [
                _vm._v("Change")
              ])
            : _vm._e()
        ]),
        _vm._l(_vm.productAddons, function(productAddon, idx) {
          return _c("product-avatar", {
            key: idx + "-addon",
            attrs: { show_desc_on_click: "", product: productAddon },
            on: {
              click: function($event) {
                return _vm.toggleAddonDec(productAddon.product_id)
              }
            }
          })
        }),
        !_vm.productAddons.length
          ? _c("div", [_vm._v("You have no selected any Service Addons.")])
          : _vm._e()
      ],
      2
    ),
    _c("div", { staticClass: "flex-grow-half" }, [
      _c(
        "div",
        { staticClass: "rates-summary" },
        [
          _c("h3", [_vm._v("Rates Summary")]),
          _vm._l(_vm.monthlyChargeList, function(chargeItem, idx) {
            return [
              _c(
                "div",
                {
                  key: idx + "-item",
                  staticClass:
                    "flex-row justify-content-between charge-item mt-2"
                },
                [
                  _c("div", [
                    chargeItem.unitType !== "flat_rate"
                      ? _c("span", [_vm._v("Per ")])
                      : _vm._e(),
                    _vm._v(_vm._s(chargeItem.unit) + " per Month Charge - ")
                  ]),
                  _c("div", [_vm._v("AU$" + _vm._s(chargeItem.price))])
                ]
              ),
              _vm._l(chargeItem.subitems, function(subItem, subIdx) {
                return _c(
                  "div",
                  {
                    key: subIdx + "-sub-item",
                    staticClass: "flex-row justify-content-between sub-item"
                  },
                  [
                    _c("div", [_vm._v(" - " + _vm._s(subItem.name) + " ")]),
                    _c("div", [_vm._v("AU$" + _vm._s(subItem.price))])
                  ]
                )
              })
            ]
          })
        ],
        2
      ),
      _vm.monthlyChargesByType
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _vm.subscription
                ? [
                    _c("h3", [_vm._v("Estimated Monthly Charges")]),
                    _c("b-table", {
                      staticClass: "summary-table",
                      attrs: {
                        items: _vm.lastVolumesList,
                        fields: _vm.totalVolumesFields,
                        "tbody-class": "td-style"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "custom-foot",
                            fn: function(items) {
                              return [
                                _c(
                                  "b-tr",
                                  [
                                    _c("b-td"),
                                    _c("b-td"),
                                    _c("b-td", [
                                      _c("strong", [_vm._v("Monthly Total")])
                                    ]),
                                    _c("b-td", [
                                      _c("strong", [
                                        _vm._v(
                                          " AU$" +
                                            _vm._s(
                                              _vm.mf.numberFormat(
                                                _vm.lastVolumesTotal,
                                                2
                                              )
                                            ) +
                                            " ex GST"
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        508402642
                      )
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }