<template>
  <div class="subscription-summary">
      <div class="justify-content-start flex-grow-half">
        <div class="flex-row justify-content-between align-items-center">
          <h5 class="text-left">Primary Subscription Tier</h5>
          <button v-if="show_change_buttons" @click="clickPrimary">Change</button>
        </div>

        <product-avatar v-if="primaryProduct" :product="primaryProduct" show_desc_on_click
                        @click="showPrimaryDescription=!showPrimaryDescription"></product-avatar>
        <div class="flex-row justify-content-between">
          <h5 class="text-left">Service Addons</h5>
          <button v-if="show_change_buttons" @click="clickSecondary">Change</button>
        </div>
        <product-avatar v-for="(productAddon, idx) of productAddons" v-bind:key="idx+'-addon'" show_desc_on_click
                        :product="productAddon" @click="toggleAddonDec(productAddon.product_id)"
        >
        </product-avatar>
        <div v-if="!productAddons.length">You have no selected any Service Addons.</div>
      </div>
      <div class="flex-grow-half">
        <div class="rates-summary">
          <h3>Rates Summary</h3>
          <template v-for="(chargeItem, idx) of monthlyChargeList">
            <div class="flex-row justify-content-between charge-item mt-2"
                 v-bind:key="idx+'-item'"
            >
              <!--                    Main Item -->
              <div>
                <span v-if="chargeItem.unitType !=='flat_rate'">Per </span>{{ chargeItem.unit }} per Month Charge -
              </div>
              <div>AU${{ chargeItem.price }}</div>
              <!--                    Sub Items -->
            </div>
            <div class="flex-row justify-content-between sub-item" v-for="(subItem, subIdx) of chargeItem.subitems"
                 v-bind:key="subIdx+'-sub-item'">
              <div>
                - {{ subItem.name }}
              </div>
              <div>AU${{ subItem.price }}</div>
            </div>
          </template>

          <!--                  <div class="flex-row justify-content-between px-5">-->
          <!--                    <div>Flat Charges per Month -</div>-->
          <!--                    <div>${{ totalFlatPerMonth }}</div>-->

          <!--                  </div>-->

        </div>
        <div class="mt-4" v-if="monthlyChargesByType">
          <template v-if="subscription">
            <h3>Estimated Monthly Charges</h3>
            <b-table :items="lastVolumesList" :fields="totalVolumesFields" class="summary-table"
                     tbody-class="td-style"
            >
              <template #custom-foot="items">
                <b-tr>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td><strong>Monthly Total</strong></b-td>
                  <b-td><strong>
                    AU${{ mf.numberFormat(lastVolumesTotal, 2) }} ex
                    GST</strong></b-td>
                </b-tr>
              </template>
            </b-table>

          </template>
        </div>
      </div>
  </div>
</template>

<script>
import * as DataProvider from '../components/helpers/DataProvider'
import * as ErrorHelper from '../components/helpers/ErrorHelper'
import ProductAvatar from '@/components/ProductAvatar.vue'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'

export default {
  name: 'SubscriptionSummary',
  props: {
    subscription: Object,
    expandable_products: Boolean,
    show_change_buttons: Boolean
  },
  components: {
    ProductAvatar
  },
  data: function () {
    return {
      loading: true,
      showPrimaryDescription: false,
      showAddonDescriptions: {},
      user: null,
      products: [],
      mf: mf,
      currentStep: 0,
      agreement: false,
      paymentOptions: [
        {
          text: 'Credit Card',
          value: 'card'
        },
        {
          text: 'Bank Account (Direct Debit)',
          value: 'bank'
        }
      ],
      paymentMethod: 'card',
      paymentEntryComplete: false,
      paymentConfirmed: false,
      paymentId: null,
      actionSteps: [
        {
          name: 'first',
          title: 'Subscription',
          complete: false,
          active: true
        },
        {
          name: 'second',
          title: 'Extras',
          complete: false,
          active: false
        },
        {
          name: 'third',
          title: 'Review',
          complete: false,
          active: false
        },
        {
          name: 'fourth',
          title: 'Payment',
          complete: false,
          active: false
        },
        {
          name: 'fifth',
          title: 'Confirmation',
          complete: false,
          active: false
        }
      ],
      ChargeTypes: [
        'tracked_assets',
        'untracked_assets',
        'all_assets',
        'users',
        'flat_rate',
      ],
      showChargeTypes: [
        'tracked_assets',
        'flat_rate',
      ],
      unitTypeText: {
        'tracked_assets': 'Tracked Asset',
        'untracked_assets': 'Untracked Asset',
        'all_assets': 'All Asset',
        'users': 'User',
        'flat_rate': 'Flat Rate',
      },
      totalVolumesFields: [
        { key: 'name',
          label: 'Item Name'
        },
        { key: 'price',
          label: 'Rate(AU$)',
          formatter: (x) => 'AU$' + mf.numberFormat(x, 2)
        },
        { key: 'volume',
          label: 'Volume',
          formatter: (x) => mf.numberFormat(x, 0)
        },
        { key: 'total',
          label: 'Total(AU$)',
          formatter: (x) => 'AU$' + mf.numberFormat(x, 2)
        },
      ],
      devices: null
    }
  },
  async created() {
    // If the user has 'fixable' subscriptions, send them to the account page, as we don't need to create
    // a new subscription for them.
    // if (await this.$auth.hasRecoverableSubscriptions()) {
    //   this.$router.push({ path: '/preferences', query: { tab: 'subscription' } })
    // }
    await this.getMyUser()
    await this.getProducts()
    this.loading = false
  },
  methods: {
    getMyUser: async function () {
      let res = await DataProvider.getUserProfile()
      if (res.success) {
        this.user = res.data
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    getProducts: async function () {
      let resp = await DataProvider.getProducts()
      if (resp.success) {
        this.products = resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    toggleAddonDec(productId) {
      if (Object.hasOwn(this.showAddonDescriptions, productId)) {
        this.showAddonDescriptions[productId] = !this.showAddonDescriptions[productId]
      } else {
        this.showAddonDescriptions[productId] = true
      }
    },
    getVolume(product) {
      if (this.subscription && this.subscription.last_volumes !== null) {
        return this.subscription.last_volumes[product.product_id]
      } else {
        return this.billableDeviceCount
      }
    },
    clickPrimary() {
      console.log('Click Pru')
      this.$emit('click-primary')
    },
    clickSecondary() {
      console.log('Click sec')
      this.$emit('click-addons')
    }
  },
  computed: {
    primaryProduct () {
      if (this.subscription) {
        console.log(this.subscription)
        return this.subscription.products.find(p => p.product_type === 'primary')
      } else {
        return null
      }
    },
    productAddons () {
      if (this.subscription) {
        return this.subscription.products.filter(p => p.product_type === 'addon')
      } else {
        return []
      }
    },
    monthlyChargesByType() {
      if (!this.subscription) {
        return null
      } else {
        let allProducts = this.subscription.products
        let chargeValues = {}
        this.showChargeTypes.forEach((chargeType) => {
          chargeValues[chargeType] = {
            unitType: chargeType,
            unit: this.unitTypeText[chargeType],
            price: mf.numberFormat(allProducts.filter(p => p.billable_unit === chargeType).reduce((total, current) => total + current.price, 0) / 100, 2),
            subitems: allProducts.filter(p => p.billable_unit === chargeType).map((subp) => {
              return {
                name: subp.name,
                price: mf.numberFormat(subp.price / 100, 2)
              }
            })
          }
        })
        return chargeValues
      }
    },
    monthlyChargeList() {
      if (this.monthlyChargesByType) {
        return Object.values(this.monthlyChargesByType)
      } else {
        return []
      }
    },
    billableDeviceCount() {
      if (this.user) {
        console.log('Devices: ', this.user.devices)
        return Object.values(this.user.devices).filter(device => device.billable === true && device.user_id === this.user.user_id).length
      } else {
        return 0
      }
    },
    trackedChargesEstimate() {
      if (this.monthlyChargesByType) {
        return this.monthlyChargesByType['tracked_assets']['price'] * this.billableDeviceCount
      } else {
        return 0
      }
    },
    lastVolumesList() {
      if (this.subscription) {
        return this.subscription.products.map((product) => {
          if (product.billable_unit === 'flat_rate') {
            // 'Licensed' billing type is fixed cost.
            return {
              name: product.name,
              price: product.price * 0.01,
              volume: 1,
              total: product.price * 0.01
            }
          } else {
            // 'metered' for per unit charges
            return {
              name: product.name,
              price: product.price * 0.01,
              volume: this.getVolume(product),
              total: product.price * this.getVolume(product) * 0.01
            }
          }
        })
      } else {
        return []
      }
    },
    lastVolumesTotal() {
      console.log(this.lastVolumesList)
      return this.lastVolumesList.reduce((total, item) => item.total + total, 0)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.subscription-summary {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  overflow: auto;
  flex-grow: 1;
}

.flex-grow-half {
  flex-grow: 0.5;
}

h3 {
  border-bottom: 1px solid $theme-color-primary-6;
  margin-bottom: 1em;
  //color: $theme-color-primary-2
}

h5 {
  margin-bottom: 0;
}

.charge-item {
  font-weight: 600;
}

.sub-item {
  margin-left: 2em;
  font-style: italic;
}

@media screen and (max-width: 420px) {
  .subscription-summary {
    font-size: 0.7em;
  }

  button {
    font-size: 0.8em;
  }

  h3 {
    font-size: 1.1em;
  }

  h5 {
    font-size: 1.5em;
  }

  .rates-summary {
    overflow: auto;
  }
  }
</style>
